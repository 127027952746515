import React from 'react';
import {useMediaQuery} from 'react-responsive';
import Banner from './images/Baron-We-The-North-Toronto-Raptors-Championship-Collection-online-store.jpg';
import TabletBanner from './images/Baron-We-The-North-Toronto-Raptors-Championship-Collection-online-store-M.jpg';
import './App.css';

function App() {
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 769px)'});
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <div className="App">
      <header className="App-header" role="presentation" aria-label="Toronto Raptors Championship Ring Collection">
      { isDesktopOrLaptop && <>
        <img src={Banner} alt="Toronto Raptors Championship Ring Collection" />
        </>}
        { isTabletOrMobile  && <>
          <img src={TabletBanner} alt="Toronto Raptors Championship Ring Collection" />
          </>}
      </header>
    </div>
  );
}

export default App;
